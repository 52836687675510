import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1074.000000 892.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,892.000000) scale(0.100000,-0.100000)">
					<path d="M5153 7342 c-13 -26 -68 -117 -124 -202 -119 -182 -258 -400 -279
-438 -8 -14 -23 -35 -32 -45 -9 -11 -24 -35 -33 -53 -8 -18 -21 -39 -28 -46
-21 -22 -77 -114 -77 -126 0 -7 -3 -12 -7 -12 -5 0 -19 -19 -33 -42 -14 -22
-53 -84 -87 -137 -43 -66 -61 -102 -57 -116 4 -14 3 -16 -4 -7 -7 11 -15 5
-33 -25 -12 -21 -25 -40 -28 -43 -15 -13 -31 -52 -26 -61 4 -5 1 -8 -6 -7 -10
3 -56 -59 -76 -102 -3 -8 -14 -25 -22 -36 -62 -84 -130 -198 -124 -208 3 -6 3
-8 -2 -4 -5 5 -15 -2 -23 -13 -12 -18 -13 -23 -1 -31 12 -8 11 -9 -1 -5 -18 5
-75 -78 -64 -94 4 -7 3 -9 -4 -6 -6 4 -35 -32 -68 -85 -32 -51 -61 -95 -64
-98 -7 -7 -70 -110 -70 -116 0 -2 -17 -28 -37 -56 -20 -29 -38 -54 -39 -56 -1
-1 -248 -2 -550 -2 -538 0 -550 0 -583 -21 -18 -11 -40 -18 -48 -15 -8 3 -11
1 -8 -4 4 -6 -7 -10 -24 -10 -17 0 -31 -4 -31 -10 0 -5 -9 -10 -19 -10 -11 0
-23 -4 -26 -10 -3 -5 -15 -10 -26 -10 -11 0 -17 -4 -14 -10 3 -5 0 -7 -7 -5
-8 3 -60 -14 -116 -38 -55 -25 -104 -42 -108 -38 -4 3 -4 0 1 -8 6 -11 3 -13
-13 -9 -12 4 -19 2 -16 -3 3 -5 17 -9 32 -8 15 1 322 -6 682 -15 360 -9 672
-16 693 -16 20 0 37 -5 37 -10 0 -10 -138 -217 -166 -251 -8 -8 -14 -24 -14
-34 0 -10 -3 -16 -6 -12 -3 3 -38 -43 -78 -101 -39 -59 -100 -149 -135 -199
-36 -54 -59 -97 -55 -104 4 -7 3 -9 -3 -6 -6 4 -37 -33 -69 -81 -33 -48 -61
-89 -64 -92 -10 -9 -150 -220 -150 -225 0 -3 -5 -11 -10 -18 -6 -7 -43 -57
-82 -112 -74 -104 -206 -270 -270 -339 -21 -23 -38 -47 -38 -53 0 -7 -3 -12
-8 -10 -4 1 -37 -27 -73 -63 -89 -89 -169 -163 -264 -245 -44 -38 -79 -73 -77
-78 1 -5 -1 -6 -5 -4 -12 8 -105 -62 -98 -73 3 -5 0 -7 -8 -4 -8 3 -21 -3 -28
-14 -8 -11 -18 -19 -22 -20 -5 0 -19 -9 -31 -19 -12 -11 -30 -22 -40 -26 -10
-4 -14 -12 -10 -18 5 -8 3 -9 -5 -5 -7 5 -20 1 -29 -7 -43 -39 -320 -175 -400
-196 -27 -8 -40 -16 -36 -23 4 -6 3 -9 -2 -5 -5 3 -59 -1 -119 -10 -128 -17
-225 -12 -309 17 -33 11 -69 18 -80 14 -16 -4 -17 -3 -7 4 11 8 7 14 -20 27
-42 22 -116 80 -148 117 -13 15 -28 25 -34 21 -5 -3 -7 -1 -3 5 3 6 -5 27 -18
47 -53 77 -92 216 -104 370 -9 105 -8 106 33 282 15 61 24 117 19 124 -4 7 -3
10 4 5 6 -4 19 19 32 59 12 36 58 136 101 222 95 186 128 238 249 388 35 44
71 85 79 91 8 7 11 16 6 24 -6 9 -4 11 4 5 28 -17 68 58 63 117 l-3 37 -51 -4
c-53 -4 -156 -32 -173 -47 -5 -4 -18 -8 -30 -8 -12 0 -26 -5 -33 -12 -6 -6
-21 -13 -31 -15 -38 -6 -256 -117 -292 -149 -8 -8 -19 -14 -23 -14 -18 0 -66
-42 -60 -52 5 -7 2 -8 -5 -4 -17 11 -51 -22 -44 -42 4 -14 3 -15 -5 -2 -9 12
-14 12 -31 -4 -11 -10 -18 -23 -15 -28 3 -4 1 -7 -4 -6 -15 5 -83 -62 -76 -74
4 -6 3 -8 -3 -5 -6 4 -43 -32 -82 -80 l-71 -87 0 -778 0 -777 26 -20 c42 -33
151 -84 183 -85 16 -1 27 -4 25 -7 -7 -12 153 -30 248 -29 114 2 281 17 329
30 19 5 54 12 76 15 99 12 422 115 460 146 7 6 13 8 13 5 0 -4 42 14 93 40
168 87 358 205 338 212 -6 2 -8 8 -5 14 4 7 9 6 14 -2 10 -16 52 10 45 28 -3
7 0 10 5 7 11 -7 75 42 75 56 0 4 4 11 9 16 5 5 6 3 2 -4 -30 -53 103 64 304
266 91 91 179 184 195 206 16 22 33 42 36 45 3 3 38 46 78 95 39 50 78 99 87
110 9 11 28 38 42 60 15 21 37 50 50 63 23 26 27 38 15 45 -5 2 -3 3 3 1 7 -1
25 16 41 39 67 95 200 282 225 315 15 21 31 44 35 52 4 8 15 24 25 35 10 10
16 25 13 33 -2 7 -1 11 3 9 6 -5 120 140 132 167 3 7 31 46 63 88 31 41 57 79
57 83 0 4 15 26 33 49 18 23 50 67 72 99 22 31 57 81 78 110 21 28 61 86 89
127 56 81 57 82 90 123 l23 27 490 -11 c856 -19 960 -23 961 -36 1 -7 2 -735
3 -1618 l1 -1605 886 0 885 0 -1 777 c-1 428 -1 785 -1 793 1 13 44 15 338 12
376 -3 799 10 999 33 136 15 308 42 334 52 8 3 30 7 48 9 18 2 48 8 65 13 18
6 66 20 107 31 79 22 182 55 215 70 11 5 47 21 80 36 33 15 65 28 70 29 9 3
67 34 168 90 49 28 185 131 238 180 69 65 209 227 209 242 0 7 3 13 8 13 11 0
43 61 36 71 -3 5 0 8 7 7 6 -2 14 3 17 11 3 9 0 11 -8 6 -9 -5 -11 -4 -6 4 4
6 11 9 16 6 11 -7 72 114 63 127 -3 7 -1 8 5 4 7 -4 14 4 18 21 4 15 10 33 14
38 16 20 89 253 109 345 16 79 44 267 51 345 12 147 9 505 -5 655 -17 177 -59
426 -76 446 -5 6 -6 13 -4 16 3 3 1 18 -6 34 -6 16 -13 40 -15 54 -7 40 -48
150 -56 150 -4 0 -4 7 -1 17 4 11 3 14 -5 9 -8 -5 -10 -2 -6 8 3 9 1 16 -6 16
-6 0 -8 5 -5 10 10 17 -6 34 -17 19 -7 -10 -8 -8 -3 8 7 25 -60 164 -77 160
-6 -1 -13 5 -15 13 -4 13 -3 13 7 0 28 -36 -2 23 -41 83 -54 80 -118 156 -199
237 -75 76 -185 162 -194 153 -3 -4 -6 -1 -6 5 0 7 -7 12 -16 12 -8 0 -13 4
-9 9 3 5 -4 13 -16 16 -12 4 -37 18 -58 32 -61 42 -191 104 -203 97 -7 -4 -8
-3 -4 4 4 7 3 12 -3 12 -5 0 -22 5 -38 12 -15 6 -30 12 -33 13 -3 1 -20 6 -37
10 -18 4 -33 11 -33 15 0 3 -6 7 -12 8 -7 1 -71 17 -143 37 -146 39 -248 58
-445 80 -114 12 -374 15 -1665 15 l-1530 0 -22 -48z m14 -450 c9 -5 11 -227
10 -909 -1 -496 -5 -904 -8 -907 -10 -11 -1293 -8 -1297 3 -3 8 114 191 199
312 10 15 82 123 160 240 78 118 149 225 159 239 89 129 150 223 150 230 0 4
3 10 8 12 8 3 304 444 311 462 2 6 7 13 10 16 4 3 33 46 65 95 33 50 72 106
87 126 16 20 26 39 24 43 -3 3 -3 4 1 2 3 -2 26 7 52 20 54 26 53 26 69 16z
m-2960 -2008 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m7820
-451 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-9332 -193 c-3
-5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m9272 -42 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-9582 -156 c-6 -7 -34 11 -29 19 3 5
11 3 19 -4 7 -6 12 -13 10 -15z m192 -364 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m1143 -1252 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5
11 10 11 6 0 10 -2 10 -4z m-512 -245 c11 -7 10 -9 -3 -14 -9 -4 -14 -3 -10 2
3 5 -1 12 -7 14 -7 3 -8 6 -3 6 6 0 16 -3 23 -8z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
